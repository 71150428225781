.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;

  }
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header { 
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  font-size: calc(10px + 2vmin);
  color: white;
 
  margin-top: -14em;
    
}

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.me-2{
  height: 40px;
  border-radius: 4em;
  width: 70%;
  padding:10px;
  font-family: Roboto;
}
.box{
  border: 2px solid gray;
  box-shadow: 0px 1px 0px pink;
  display: flex;
  max-width: 30%;
  height: 100px;
  align-items: top;
  margin: 0 12px;
  margin-bottom: -20em;
  position: relative;
  bottom: -400px;

}

