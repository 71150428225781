@media screen and (min-width: 900px) {
    .card-style{
        width: 500px;
    }
    .card-description{
        width: 450px;
        padding: 2%;
        display: inline-block;
    }
    .card-title{
        font-size:20px;
        width: 450px;
        font-family: Arial, Helvetica, sans-serif;
        line-height: 24px;
        text-decoration: none;
        display: inline-block;
    }

    }
    
    @media screen and (max-width: 600px) {
        .card-style{
            width: 300px;
        }
        .card-description{
            width: 230px;
            padding: 2%;
            display: inline-block;
        }
        .card-title{
            font-size:17px;
            width: 250px;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 10px;
            text-decoration: none;
            display: inline-block;
        }
      }