@media screen and (min-width: 900px) {
    .search-box{
        padding: 0 10% 0 10%;
    }
    }
    
    @media screen and (max-width: 600px) {
        .search-box{
            padding: 0 1% 0 1%;
            width: 100%;
        }
        button{
        position: relative;
            margin-left: 20px;
          padding-right:20px;
        background-color:transparent;
        border: 2px solid transparent;
        border-radius: 50%;
    cursor: pointer;
        /* color: crimson; */
    }
        .me-2{
  height: 40px;
  border-radius: 4em;
  width: 70%;
            margin-right: 20%;

  padding:10px 0px 10px 10px;
  font-family: Roboto;
}
      }
      .d-flex .buttons{
        display:inline-flex;
        border: none;
        margin-left: -74px;
        height: 2em;        
        border-top-right-radius: 1.5em;
        border-bottom-right-radius: 1.5em;

    }
button{
        position: relative;
        top:12px;
        background-color:transparent;
        border: 2px solid transparent;
        border-radius: 50%;
    cursor: pointer;
        /* color: crimson; */
    }